import React, { Component } from "react"
import { navigate } from "gatsby"
import firebase from "../../../utils/database"
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/all'
import '../Form.css'
import './LaunchBusiness.css'

export default class FileUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: 0,
      wiggleCounter: 0,
      buttonLabel: "Submit",
      uploadLabel: "Screenshot of your LLC receipt"
    }
    gsap.registerPlugin(ScrollToPlugin)
    this.handleSubmit = this.handleSubmit.bind(this)
  }


  componentDidMount() {
    gsap.to('.Challenge--H1-Img', .2, { scale: 0.7 }, 0.05)
    const wiggleElement = document.getElementById("Challenge--H1-Img")
    this.wiggleProp(wiggleElement, 'scale', 0.93, 1)
    this.wiggleProp(wiggleElement, 'rotation', -5, 5)
    this.wiggleProp(wiggleElement, 'x', -3, 3)
    this.wiggleProp(wiggleElement, 'y', -3, 3)
  }

  //SHAKE IT LIKE A ROCKETS TAKING OFF!
  wiggleProp = (element, prop, min, max, counter = 0) => {
    var duration = Math.random() * (.1 - .2) + .1
    var tweenProps = {
      ease: gsap.easeInOut,
      onComplete: this.wiggleProp,
      onCompleteParams: [element, prop, min, max, this.state.wiggleCounter]
    };
    if (counter < 75) {
      tweenProps[prop] = Math.random() * (max - min) + min
      gsap.to(element, duration, tweenProps)
      this.state.wiggleCounter++
    } else {
      gsap.to(element, 1, { scale: 1, rotation: 0, x: 0, y: 0 })
      gsap.to(window, 1, { scrollTo: { y: 500 } })
      return false
    }
  }


  handleSubmit(event) {
    const website = document.getElementById("Form--Website").value
    const email = document.getElementById("Form--Email").value
    const socialHandle = document.getElementById("Form--Social").value
    const uploadLabelProgress = document.getElementById("Form--Upload-Progress")

    event.preventDefault();
    // const submitButton = document.getElementById("SubmitButton")
    // submitButton.innerHTML = this.state.progress + '% uploaded' //BP TODO: weave infinity loader?
    const file = this.fileInput.files[0]
    let uploadTask = firebase.storage()
      .ref("receipts") //the storage bucket
      .child(file.name)
      .put(file);

    uploadTask.on(
      "state_changed",
      snapshot => {
        // Handle progress of upload
        let progress = Math.round(
          snapshot.bytesTransferred / snapshot.totalBytes * 100
        );
        this.setState({ progress, buttonLabel: progress + '% uploaded' })
        uploadLabelProgress.style.width = progress + '%'
      },
      error => {
        // Handle unsuccessful upload
        console.error(error)
        this.setState({ buttonLabel: 'UH OH! TRY AGAIN' })
      },
      () => {
        // Handle successful upload
        this.setState({
          progress: 100,
          buttonLabel: "UPLOADED!"
        });
        uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
          //console.log('Your uploaded image is now available at ' + downloadURL);
          firebase
            .firestore()
            .collection("launch_challenge_2020")
            .add({
              website,
              email,
              socialHandle,
              receiptUrl: downloadURL,
              created: firebase.firestore.FieldValue.serverTimestamp()
            }).catch(err => {
              console.warn({ err })
            })
            .then(ref => {
              //SEND EMAIL VIA SERVERLESS API FUNCTION - we may want to bring this back...
              // fetch('/api/email', {
              //   method: "POST",
              //   headers: { "Content-Type": "application/json" },
              //   body: JSON.stringify({
              //     to: process.env.GATSBY_EMAIL_TO,
              //     subject: "Weave Connected Challenge Accepted",
              //     textBody: "id: " + ref.id + "\r\n website: " + website + "\r\n email: " + email + "\r\n socialHandle: " + socialHandle + "\r\n receipt: " + downloadURL,
              //     emailBody: "<strong>id:</strong> " + ref.id + "<br><strong> website:</strong> " + website + "<br><strong> email:</strong> " + email + "<br><strong> socialHandle:</strong> " + socialHandle + "<br><strong> receipt: </strong>" + downloadURL,
              //   })
              // })
             
              fetch('/api/marketo', {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  email,
                  notes: "id: " + ref.id + "\r\n website: " + website + "\r\n email: " + email + "\r\n socialHandle: " + socialHandle + "\r\n receipt: " + downloadURL,
             
                })
              })
                .then(() => navigate('/thanks/'))
            })
          }
        )
      }
    )
  }

  uploadChanged() {
    this.setState({
      uploadLabel: 'Uploading ' + document.getElementById("Form--Upload").files[0].name
    })
  }

  render() {
    const name = "Challenge Form"
    return (
      <form onSubmit={this.handleSubmit}
        className="Form"
        name={name}
      >

        <div className="Flex--Row">

          <label className="Form--Label Input--Half">
            <input
              className="Form--Input Input--Website"
              type="text"
              placeholder="Website"
              name="name"
              id="Form--Website"
              required
            />
          </label>
          <label className="Form--Label Input--Half">
            <input
              className="Form--Input Input--Social"
              type="text"
              placeholder="Social Handles"
              name="email"
              id="Form--Social"
              required
            />
          </label>
        </div>
        <div className="Flex--Row">

          <label className="Form--Label Input--Half">
            <input
              className="Form--Input Input--Email"
              type="email"
              placeholder="Business Email"
              name="email"
              id="Form--Email"

            />
          </label>

        </div>
        <label className="Form--Upload-Label" id="Form--Upload-Label">
          <span className="Form--Label-Text">
            {this.state.uploadLabel}
          </span>
          <input
            type="file"
            id="Form--Upload"
            accept="image/*, .pdf"
            multiple={false}
            ref={input => {
              this.fileInput = input;
            }}
            placeholder="Upload Receipt"
            className="Form--Input Form--UploadButton"
            onChange={() => this.uploadChanged()}
            required
          />
        </label>

        <input type="text" name="_gotcha" style={{ display: 'none' }} />

        <button className="Button Form--Submit-Button" type="submit" id="SubmitButton">{this.state.buttonLabel}</button>
        <div className="Form--Progress-Container">
          <span className="Form--Upload-Progress" id="Form--Upload-Progress"></span>
        </div>
      </form>
    );
  }
}
