import React from 'react'
import { graphql } from 'gatsby'
import LaunchBusinessForm from '../components/Forms/Challenge/LaunchBusiness'
import Content from '../components/Content'
import Layout from '../components/Layout'
import './ChallengePage.css'


// Export Template for use in CMS preview
export const ChallengePageTemplate = ({
  body,
  title,
  section1,
  sectionHowTo,
  sectionNext,
  h1,
  h2,
  h2HowTo,
  h2Next,
  h3,
  prize1,
  prize2,
  prize3
}) => (
    <main className="Challenge">
      <div className="Challenge--Header center" id="Challenge--Header">
        <h1 className="Challenge--H1"><Content source={h1} /></h1>
        <img src="/images/ChallengeH1.png" alt="Weave Connected Challenge" className="Challenge--H1-Img" id="Challenge--H1-Img" />
        <h3 className="Challenge--H3"><Content source={h3} /></h3>
      </div>

      <section className="section Challenge--Section1">
        <div className="container Challenge--Section1--Container">
          <div>
            <hr className="Challenge--Hr" />
            <h2 className="Challenge--H2"> <Content source={h2} /></h2>
            <Content source={section1} />
            <Content source={body} />
            <div className="Grid--Container-3-Col Content">
              <div className="Grid--Col">
                <span className="Challenge--Prize">1<sup className="Challenge--Prize-suffix">st</sup></span>
                <Content source={prize1} />
              </div>
              <div className="Grid--Col Challenge--Middle-Col">
                <span className="Challenge--Prize">2<sup className="Challenge--Prize-suffix">nd</sup></span>
                <Content source={prize2} />
              </div>
              <div className="Grid--Col">
                <span className="Challenge--Prize">3<sup className="Challenge--Prize-suffix">rd</sup></span>
                <Content source={prize3} />
              </div>
            </div>
            <h2> <Content source={h2HowTo} /></h2>
            <Content source={sectionHowTo} />
            <h2> <Content source={h2Next} /></h2>
            <Content source={sectionNext} />
          </div>
        </div>
        {/* <div className="container">
          <LaunchBusinessForm name="Challenge Form" />
        </div> */}
      </section>

    </main>
  )

const ChallengePage = ({ data: { page } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <ChallengePageTemplate {...page.frontmatter} body={page.html} />
  </Layout>
)

export default ChallengePage

export const pageQuery = graphql`
  query ChallengePage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        section1
        sectionHowTo
        sectionNext
        h1
        h2
        h2HowTo
        h2Next
        h3
        prize1
        prize2
        prize3
      }
    }
  }
`
